<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }" v-if="!noaction">
      <v-tooltip bottom v-if="icon">
      <template v-slot:activator="{ on: tooltip }">
        <v-btn v-on="{ ...tooltip, ...on }" class="ma-2" fab x-small dark elevation="0" :color="buttoncolor"><v-icon>{{ buttonicon }}</v-icon></v-btn>
      </template>
      <span>Re-Spot</span>
      </v-tooltip>
      <v-btn v-on="on" v-else-if="!menu && !card" class="ma-2" slot="activator" :color="buttoncolor" :x-small="small">{{ title }} <v-icon dark :v-if="buttonicon">{{ buttonicon }}</v-icon></v-btn>
      <v-card v-on="on" v-else-if="card" flat shaped class="spot-card">
        <v-card-text>
          <h1>At a park now?</h1>
          <v-icon size="100px">{{$vuetify.icons.values.mdiPlus}}</v-icon>
          <h1>Click here to add your spot!</h1>
        </v-card-text>
      </v-card>
      <v-list-item v-on="on" v-else-if="menu">
        <v-list-item-action>
          <v-icon>{{ $vuetify.icons.values.mdiMapMarkerPlusOutline }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Add Spot</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="headline green white--text" primary-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-text-field v-if="pspot || pactivator != ''" filled readonly v-model="activator" label="Activator Callsign" required></v-text-field>
                <v-text-field v-else v-model="activator" label="Activator Callsign" @keyup="activator = activator.toUpperCase()" :rules="activatorRules" :autofocus="isNewSpot" required></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field v-if="isAuthenticated" filled readonly v-model="spotter" label="Spotter Callsign" required></v-text-field>
                <v-text-field v-else v-model="spotter" label="Spotter Callsign" @keyup="spotter = spotter.toUpperCase()" :rules="spotterRules" required></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field v-if="isAuthenticated || true" v-model="frequency" label="Frequency (kHz)" :rules="frequencyRules" required></v-text-field>
                <v-text-field v-else filled readonly v-model="frequency" label="Frequency (kHz)" required></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <park-reference-entry v-if="isAuthenticated || true" v-model="reference" label="Park Reference" required></park-reference-entry>
                <park-reference-entry v-else filled readonly v-model="reference" label="Park Reference" required></park-reference-entry>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-if="requireComment" v-model="comments" label="Comments" :rules="commentsRules" @keyup="scanComments" @keyup.enter="save" :messages="commentMessage" required></v-text-field>
                <v-text-field v-else-if="isAuthenticated" v-model="comments" label="Comments" @keyup="scanComments" @keyup.enter="save" :messages="commentMessage" :autofocus="!isNewSpot"></v-text-field>
                <v-text-field v-else label="Comments" hint="Login to add a comment" persistent-hint filled readonly></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <small>
            <b class="success--text">Wrong mode?</b> Mention a mode in the Comments field to change the mode for this spot (e.g. "QSY CW", "RTTY" or "Switching to FT8").<br>
            <b class="success--text">Mention QRT</b> if this activator is no longer on the air to mark this spot as finished.<br>
            This spot will be <b class="success--text">marked hunted</b> for this band and mode.
          </small>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancel</v-btn>
        <v-btn color="green white--text" @click="save">Spot</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import ParkReferenceEntry from '@/components/ParkReferenceEntry';

export default {
  components: { ParkReferenceEntry },
  name: 'SpotForm',
  props: {
    title: { default: "Add Spot" },
    buttoncolor: { default: "orange accent-1 black--text" },
    buttonicon: { default: "" },
    headingcolor: { default: "" },
    card: { type: Boolean, default: false },
    menu: { type: Boolean, default: false },
    spot: { default: "" },
    pactivator: { default: "" },
    pspotter: { default: "" },
    pfrequency: { default: "" },
    preference: { default: "" },
    pmode: { default: "" },
    pcomments: { default: "" },
    requireComment: { type: Boolean, default: false },
    noaction: { type: Boolean, default: false },
    activate: { type: Boolean, default: false },
    icon: { default: false, type: Boolean },
    small: { default: false, type: Boolean },
  },
  mounted() {
    if (this.activate)
    {
      this.dialog = true;
    }
  },
  data: function () {
    return {
      dialog: false,
      valid: true,
      activator: (this.pactivator == '' ? this.$store.state.config.callsign : this.pactivator),
      spotter: (this.pspotter == '' ? this.$store.state.config.callsign : this.pspotter),
      frequency: this.pfrequency,
      mode: this.pmode,
      reference: this.preference,
      comments: this.pcomments,
      commentMessage: '',
      activatorRules: [
        v => !!v || 'Example: N0CALL',
        v => this.$store.state.global.validCallsignRegex.test(v) || 'Example: N0CALL'
      ],
      spotterRules: [
        v => !!v || 'Example: N0CALL',
        v => this.$store.state.global.validCallsignRegex.test(v) || 'Example: N0CALL'
      ],
      frequencyRules: [
        v => !!v || 'Frequency (kHz) required',
        v => /^[\d.]+$/.test(v) || 'Example: 7123 or 14234',
        v => parseInt(v) > 1000 || 'Frequency in kHz (> 1000)'
        // v => /[\d.]+/.test(v) || 'Frequency (kHz), ex 14150'
      ],
      referenceRules: [
        v => !!v || 'Example: US-0001',
        v => this.$store.state.global.validReferenceRegex.test(v) || v == 'K-TEST' || 'Example: US-0001',
      ],
      commentsRules: [
        v => !!v || 'Comment is required',
      ],
      pspot: null
    }
  },
  computed: {
    isNewSpot: {
      get() {
        return this.spot == '';
      },
    },
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated
      }
    },
    hasSlotContent: {
      get() { return !!this.$slots['default'] || !!this.$scopedSlots['default']; }
    },
    modes: {
      get() {
        var modes = this.$store.state.adif.allSubmodes;
        modes.sort((a, b) => {
          return b.length - a.length // reverse sort so we find longest modes first
        })
        return modes;
      }
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.load()
      }
    },
  },
  methods: {
    openDialog(spot) {
      this.dialog = true;
      this.load();

      if (spot)
      {
        this.activator = spot.activator;
        this.frequency = spot.frequency;
        this.reference = spot.reference;
        this.mode = spot.mode;
        this.comments = '';
        this.pspot = spot;
      }
    },
    scanComments() {
      var comment = this.comments
      var upper = comment.toUpperCase()

      if (upper.includes('QRT'))
      {
        this.commentMessage = 'This spot will be marked as QRT'
        return
      }

      var words = upper.split(' ')
      for (var i = 0; i < words.length; i++)
      {
        if (this.modes.includes(words[i]) || words[i] == 'VARA')
        {
          var mode = words[i]
          if (mode == 'LSB' || mode == 'USB')
            mode = 'SSB'

          if (mode != this.pmode)
          {
            this.commentMessage = "This spot's mode will be set to " + mode
            return
          }
        }
      }

      // if we made it this far then the spot is not QRT and doesn't contain a recognized mode
      this.commentMessage = ''
    },
    load() { 
      if (this.pspot)
        return;
      this.activator = (this.pactivator == '' ? this.$store.state.config.callsign : this.pactivator)
      this.spotter = (this.pspotter == '' ? this.$store.state.config.callsign : this.pspotter)
      this.frequency = this.pfrequency
      this.reference = this.preference

      if (this.spot)
      {
        this.activator = this.spot.activator;
        this.frequency = this.spot.frequency;
        this.reference = this.spot.reference;
        this.mode = this.spot.mode;
        this.comments = '';
        this.pspot = this.spot;
      }
    },
    save () {
      if (this.$refs.form.validate()) {
        /*var mode = this.mode

        if (this.comments)
        {
          var upper = this.comments.toUpperCase()
          var words = upper.split(' ')
          for (var i = 0; i < words.length; i++)
          {
            if (modes.includes(words[i]))
            {
              mode = words[i]
              if (mode == 'LSB' || mode == 'USB')
                mode = 'SSB'

              if (mode != this.pmode)
                break
            }
          }
        }*/

        // Native form submission is not yet supported
        const formData = {
          activator: this.activator,
          spotter: this.spotter,
          frequency: this.frequency,
          reference: this.reference,
          mode: this.mode, // old mode, not potential new mode from comments
          source: 'Web',
          comments: this.comments
        }

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/spot`, formData)
          .then((response) => {
            this.$store.commit('SET_SPOTS', response.data) // update spots with POST response data
            this.$store.dispatch('addHunted', formData)
            //axios.get('https://api.pota.us/spot/cache/invalidate') // cross invalidate cache
          })
          .catch(err => {
            this.$dialog.error({ title: 'Error', text: `${err.response.data}` })
          })
          //.then(() => { this.$store.dispatch('getSpots') })
          //.then(() => { this.$store.dispatch('getHunted') })
        axios
          .post(`https://${process.env.VUE_APP_API_URL}/slack/spot`, formData)

        //this.$refs.form.reset()

        this.activator = '';
        this.spotter = '';
        this.frequency = '';
        this.reference = '';
        this.comments = '';
        this.pspot = null;
        this.commentMessage = '';

        this.dialog = false
      }
    },
    close () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.spot-card {
  text-align: center;
  border-radius: 40px;
}
.spot-card h1 {
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
